import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { InitialCard } from '@components';
import { PieReport, reportsHttp } from '@network';

interface Props {
  range: string;
  src: 'userCrops' | 'creditCrops';
  title: string;
  setLoader: (num: number, value: boolean) => void;
}

export const CropsReportGraph = ({ range, title, src, setLoader }: Props) => {
  const [numbers, setNumbers] = useState<PieReport[]>();
  const cls = useStyles();
  const chartId = src;
  const colors = ['#B44436', '#204BCE', '#1E2C5C', '#225236', '#FD8A32', '#3E8B5B', '#400E04'];

  const loadNumbers = async () => {
    setLoader(src === 'creditCrops' ? 19 : 20, true);
    try {
      const nextNumbers = await reportsHttp[src]({ range });
      setNumbers(nextNumbers);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(src === 'creditCrops' ? 19 : 20, false);
    }
  };

  useEffect(() => void loadNumbers(), [range]);

  useLayoutEffect(() => {
    if (!numbers) {
      return;
    }
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: 80,
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        radius: 40,
      }),
    );

    series.set('colors', am5.ColorSet.new(root, {
      colors: [
        am5.color(0xB44436),
        am5.color(0x204BCE),
        am5.color(0x1E2C5C),
        am5.color(0x225236),
        am5.color(0xFD8A32),
        am5.color(0x3E8B5B),
        am5.color(0x400E04),
      ],
    }));

    series.slices.template.setAll({
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
      cornerRadius: 4,
    });

    series.slices.template.states.create('hover', {
      shadowOpacity: 1,
      shadowBlur: 10,
    });

    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    });

    series.states.create('hidden', {
      endAngle: -90,
    });

    series.data.setAll(numbers);
    series.labels.template.set('visible', false);
    series.ticks.template.set('visible', false);
    series.slices.template.setAll({
      tooltipText: '[bold]Crop:[/] {category}\n[bold]Values:[/] {value}\n[bold]Percent:[/] {percent}%',
    });

    // center text
    chart.seriesContainer.children.push(am5.Label.new(root, {
      textAlign: 'center',
      centerY: am5.p50,
      centerX: am5.p50,
      text: numbers && numbers.length > 0 ? '[bold fontSize:28px]Crops[/]' : undefined,
    }));

    // show/dispose
    series.appear(1000, 100);

    return () => void root.dispose();
  }, [chartId, numbers]);

  if (!numbers) {
    return null;
  }

  return (
    <InitialCard left={title} extraPadding fullHeight boxClass={cls.box}>
      <Row>
        <Col sm={24} md={9}>
          <div className={cls.labels}>
            {numbers.length ? numbers.map((row, idx) => (
              <div key={idx} className={cls.label}>
                <div
                  className={cls.labelColor}
                  style={{ backgroundColor: colors[idx] || colors[0] }}
                />
                <div className={cls.labelText}>{row.category}</div>
                <div>{row.percent}%</div>
              </div>
            )) : null}
          </div>
        </Col>
        <Col sm={24} md={15}>
          <div className={cls.chart}>
            <div id={chartId} style={{ height: 300 }} />
          </div>
        </Col>
      </Row>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: '0 !important',
  },
  chart: {
    marginTop: -40,
  },
  labels: {
    marginTop: 30,
    padding: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  labelColor: {
    width: 20,
    height: 20,
    flex: '0 0 20px',
    borderRadius: 5,
  },
  labelText: {
    marginLeft: 10,
    minWidth: 200,
  },
});
