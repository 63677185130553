import { clsx } from 'clsx';
import { ColumnsType } from 'antd/es/table';
import { Select, Table as AntTable } from 'antd';
import { useEffect, useState } from 'react';

import { InitialCard } from '@components';
import { PaidReportsProduct } from '@network';
import cls from './Table.module.css';

interface Props {
  list: PaidReportsProduct[];
  loading: boolean;
}

const columns: ColumnsType<PaidReportsProduct> = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    align: 'center',
    className: clsx(cls.col, cls.productCol),
    // render: text => <a>{text}</a>,
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
    align: 'center',
    className: clsx(cls.col, cls.brandCol),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    align: 'center',
    className: clsx(cls.col, cls.categoryCol),
  },
  {
    title: 'Subcategory',
    dataIndex: 'subcategory',
    key: 'subcategory',
    align: 'center',
    className: clsx(cls.col, cls.subcatCol),
  },
  {
    title: 'Picture',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    className: clsx(cls.col, cls.pictureCol),
    render: text => <img alt={text} src={text} className={cls.img} />,
  },
  {
    title: 'Purchased Quantity',
    dataIndex: 'qty',
    key: 'qty',
    align: 'center',
    className: clsx(cls.col, cls.qtyCol),
  },
];

export const Table = ({ list, loading }: Props) => {
  const [qty, setQty] = useState('5');
  const [data, setData] = useState<PaidReportsProduct[]>(list.slice(0, 6));

  useEffect(() => {
    setData(qty === '' ? list : list.slice(0, +qty));
  }, [qty]);

  useEffect(() => {
    setData(list.slice(0, +qty));
  }, [list]);

  return (
    <InitialCard wrapperClass={cls.card} right={<div className={cls.filter}>
      <Select
        className={cls.select}
        popupClassName={cls.popup}
        defaultValue={qty}
        onSelect={setQty}
        onClear={() => setQty('')}
        placeholder="Show all"
        allowClear
        options={[
          { label: 'Top 5', value: '5' },
          { label: 'Top 10', value: '10' },
          { label: 'Top 20', value: '20' },
        ]}
      />
    </div>} extraPadding>
      <AntTable
        columns={columns}
        dataSource={data.length ? data : []}
        bordered
        loading={loading}
        size={'middle'}
        className={cls.table}
        rowClassName={cls.tableRow}
        pagination={qty ? false : { position: ['bottomRight'] }}
      />
    </InitialCard>
  );
};
