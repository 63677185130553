import { makeStyles } from '@mui/styles';
import { Col, Row } from 'antd';
import { useGetIdentity } from 'react-admin';
import React, { useEffect, useState } from 'react';

import { rbac } from '@utils';
import { ReportType } from '@layout';
import { DateRangeControlledInput, InitialFilters, Loader, SelectControlledInput } from '@components';
import { useSettingsContext } from '@providers/settings-provider';
import { GeneralNumbersReport } from './GeneralNumbersReport';
import { GeneralNumbersGraphReport } from './GeneralNumbersGraphReport';
import { CreditsStatusesReport } from './CreditsStatusesReport';
import { AdminLoginCounterReport } from './AdminLoginCounterReport';
import { CallsReport } from './CallsReport';
import { NewCompletedFilesReport } from './NewCompletedFilesReport';
import { NewDocumentsUploadedReport } from './NewDocumentsUploadedReport';
import { ReachToWomenReport } from './ReachToWomenReport';
import { RegistrationsGenderReport } from './RegistrationsGenderReport';
import { ContactUsReport } from './ContactUsReport';
import { RegisterCreditReport } from './RegisterCreditReport';
import { VisitorsReport } from './VisitorsReport';
import { FinancialRankingReport } from '@pages/dashboard/FinancialRankingReport';
import { useDebounce, useQuery } from '@hooks';
import { DashboardControls } from '@pages/dashboard/DashboardControls';
import { ParCreditsReport } from '@pages/dashboard/ParCreditsReport';
import { OptionsButton } from '@components/GridList/OptionsButton';
import { AllocationRevenueGoals } from './AllocationRevenueGoals/AllocationRevenueGoals';
import { NewRegistrationsReport } from '@pages/dashboard/NewRegistrationsReport';
import { AllocationReport } from '@pages/dashboard/AllocationReport';
import { InvestorReportProgress } from './InvestorReportsProgress';
import { CropsReportGraph } from './CropsReportGraph';

const REPORTS_SHOW_DELAY = 600;

export const Dashboard = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { setReportsOpened } = useSettingsContext();
  const { admin, updateAdmin } = useSettingsContext();
  const [range, setRange] = useState<string>(admin?.reportRange || '');
  const [loader, setLoader] = useState<number[]>([]);
  const [type, setType] = useState<string>(admin?.reportType || 'month');
  const [reports, setReports] = useState<Record<ReportType, boolean>>({} as any);
  const [isReportsEmpty, setIsReportsEmpty] = useState(Object.keys(reports).length === 0);

  const [updatedTime, setUpdatedTime] = useState<number>(0);
  const setUpdated = () => void setUpdatedTime((new Date()).getTime());

  const cls = useStyles();
  const query = useQuery();
  const isInnovaworx = rbac.isInnovaworx();
  const debouncedReports = useDebounce((admin?.reports || []).join(''), REPORTS_SHOW_DELAY);

  const onSetLoader = (num: number, status: boolean) => {
    if (!status) {
      setLoader(prev => prev.filter(i => i !== num));
    } else {
      setLoader(prev => [...prev, num]);
    }
  };

  useEffect(() => {
    onSetLoader(0, true);
  }, []);

  useEffect(() => {
    if (admin?.reports?.length === 0) {
      onSetLoader(0, false);
    } else {
      setTimeout(() => {
        onSetLoader(0, false);
      }, 2000);
    }
  }, [admin?.reports]);

  useEffect(() => {
    if (admin && (admin.reportRange !== range || admin.reportType !== type)) {
      updateAdmin({ reportRange: range || '', reportType: type || '' });
    }
  }, [range, type]);

  useEffect(() => {
    if (admin && (admin.reportRange !== range || admin.reportType !== type)) {
      setRange(admin.reportRange || '');
      setType(admin.reportType || 'month');
    }
  }, [admin]);

  useEffect(() => {
    const nextReports = (admin?.reports || []).reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {} as Record<ReportType, boolean>);
    setReports(nextReports);
    setIsReportsEmpty(!admin?.reports || Object.keys(nextReports).length === 0);
  }, [debouncedReports]);

  if (!identity || identityLoading || isInnovaworx) {
    return null;
  }

  return (
    <div className={cls.wrap}>
      <div className={cls.header}>
        <InitialFilters>
          <DateRangeControlledInput range={range} setRange={setRange} />
          <SelectControlledInput
            defaultValue={type}
            value={type}
            options={[
              { value: 'day', label: 'Day' },
              { value: 'week', label: 'Week' },
              { value: 'month', label: 'Month' },
              { value: 'quarter', label: 'Quarter' },
              { value: 'year', label: 'Year' },
            ]}
            onChange={nextType => setType(nextType)}
          />
          {query.get('controls') === '' && <DashboardControls />}
        </InitialFilters>
        <div className={cls.headerActions}>
          <OptionsButton onClick={setReportsOpened} text="Reports options" />
        </div>
      </div>

      {loader.length ? <Loader/> : isReportsEmpty ? (
        <div>No dashboard. Please choose the required in &quot;Reports options&quot;.</div>
      ) : null}

      {reports['Credits'] && <GeneralNumbersReport range={range} setLoader={onSetLoader} />}
      {reports['Credits graph'] && <GeneralNumbersGraphReport range={range} type={type} setLoader={onSetLoader} />}
      {reports['Current Pipeline'] && <CreditsStatusesReport range={range} type={type} setLoader={onSetLoader} />}
      {reports['visitors-report'] && <VisitorsReport range={range} type={type} setLoader={onSetLoader} />}
      {reports['new-registrations'] && <NewRegistrationsReport range={range} type={type} setLoader={onSetLoader} />}
      {reports['register-credit-progress'] && <RegisterCreditReport range={range} type={type} setLoader={onSetLoader} />}

      {reports['allocation-report'] && (
        <AllocationReport
          range={range} type={type} updatedTime={updatedTime} setLoader={onSetLoader} />
      )}
      {reports['allocation-revenue-goals']
        && <AllocationRevenueGoals setUpdated={setUpdated} setLoader={onSetLoader} />}
      {reports['investor-reports-progress'] && <InvestorReportProgress range={range} setLoader={onSetLoader} />}

      {reports['Admin Login Counter'] || reports['Calls'] || reports['Contact Us entries'] ? (
        <Row gutter={[24, 24]} align="stretch" className={cls.row}>
          {reports['Admin Login Counter'] && (
            <Col xs={24} sm={24} md={7}>
              <AdminLoginCounterReport range={range} type={type} setLoader={onSetLoader} />
            </Col>
          )}
          {reports['Calls'] && (
            <Col xs={24} sm={24} md={10}>
              <CallsReport range={range} setLoader={onSetLoader} />
            </Col>
          )}
          {reports['Contact Us entries'] && (
            <Col xs={24} sm={24} md={7}>
              <ContactUsReport range={range} setLoader={onSetLoader} />
            </Col>
          )}
        </Row>
      ) : null}

      {reports['New Documents Uploaded'] || reports['New Completed Files'] ? (
        <Row gutter={[24, 24]} align="stretch" className={cls.row}>
          {reports['New Documents Uploaded'] && (
            <Col xs={24} sm={24} md={12}>
              <NewDocumentsUploadedReport range={range} setLoader={onSetLoader} />
            </Col>
          )}
          {reports['New Completed Files'] && (
            <Col xs={24} sm={24} md={12}>
              <NewCompletedFilesReport range={range} setLoader={onSetLoader} />
            </Col>
          )}
        </Row>
      ) : null}

      {reports['par-credits'] && <ParCreditsReport range={range} setLoader={onSetLoader} />}

      {reports['Verqor\'s reach to women'] && <ReachToWomenReport range={range} setLoader={onSetLoader} />}

      {reports['Registrations - gender'] || reports['Clients - gender'] ? (
        <Row gutter={[24, 24]} align="stretch" className={cls.row}>
          {reports['Registrations - gender'] && (
            <Col xs={24} sm={24} md={12}>
              <RegistrationsGenderReport
                title="Registrations - gender"
                range={range}
                dataSource="usersGender"
                setLoader={onSetLoader}
              />
            </Col>
          )}
          {reports['Clients - gender'] && (
            <Col xs={24} sm={24} md={12}>
              <RegistrationsGenderReport
                title="Clients - gender"
                range={range}
                dataSource="clientsGender"
                setLoader={onSetLoader}
              />
            </Col>
          )}
        </Row>
      ) : null}

      {reports['user-crops'] || reports['credit-crops'] ? (
        <Row gutter={[24, 24]} align="stretch" className={cls.row}>
          {reports['user-crops'] ? (
            <Col xs={24} sm={24} xxl={12}>
              <CropsReportGraph range={range} title="User crops" src="userCrops" setLoader={onSetLoader} />
            </Col>
          ) : null}
          {reports['credit-crops'] ? (
            <Col xs={24} sm={24} xxl={12}>
              <CropsReportGraph range={range} title="Financed crops" src="creditCrops" setLoader={onSetLoader} />
            </Col>
          ) : null}
        </Row>
      ) : null}

      {reports['financial-ranking'] && <FinancialRankingReport range={range} setLoader={onSetLoader} />}
    </div>
  );
};

const useStyles = makeStyles({
  wrap: {
    padding: '22px 34px 44px 44px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerActions: {
    '& .options-btn': {
      minWidth: '140px!important',
    },
  },
  row: {
    marginTop: 24,
    marginBottom: 24,
  },
});
